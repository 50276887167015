<template>
    <div class="filter-container" ref="filter">
        <slot name="filter-header"></slot>
        <p v-if="selectedList.length > 0" class="total-text">
            總保費：{{ calculateTotal }} 元
        </p>
        <section v-for="(item, index) in selectedList" :key="item.uuid">
            <ProductFilterCard
                :ref="`list-${item.uuid}`"
                v-model="selectedList"
                :uuid="item.uuid"
                :suppliers="transformCompanyList"
                :index="index"
                @add-list="addList"
                @copy-list="copyList"
                @remove-list="removeList"
                :class="{ hide: isExpand ? false : index >= 3 }"
            />
        </section>
        <div
            v-if="selectedList.length > 3"
            @click="expandList()"
            class="toggle-content-icon size expand"
            :class="{
                expand: isExpand || selectedList.length > 3,
                collapse: !isExpand || selectedList.length < 3
            }"
        ></div>
        <div>
            <slot name="filter-bottom" :payload="selectedList"></slot>
        </div>
    </div>
</template>
<script>
import ProductFilterCard from './ProductFilterCard.vue'
import { getProductSupplierListAPI } from '../../assets/javascripts/api'
import _ from 'lodash'
export default {
    name: 'ProductFilter',
    components: { ProductFilterCard },
    emits: [],
    props: {},
    methods: {
        checkAllList: function () {
            let valid = true
            let isLoading = false
            for (let i = 0; i < this.selectedList.length; i++) {
                const ref = `list-${this.selectedList[i].uuid}`
                if (this.$refs[ref].$data.isLoading) {
                    isLoading = true
                    break
                }
                const validity = this.$refs[ref].checkFormValid()
                if (!validity) valid = false
            }
            if (isLoading) {
                valid = false
            } else if (!this.isExpand && this.selectedList.length >= 3) {
                this.expandList()
            }
            return valid
        },
        getCompanyList: async function () {
            this.$showLoading()
            try {
                let response = await getProductSupplierListAPI()
                this.dropdownList = response?.data?.supplier
            } catch (error) {
                console.log(error)
            } finally {
                this.$hideLoading()
            }
        },
        createUUID: function () {
            return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(
                /[xy]/g,
                function (c) {
                    var r = (Math.random() * 16) | 0,
                        v = c == 'x' ? r : (r & 0x3) | 0x8
                    return v.toString(16)
                }
            )
        },
        copyList: function (index, data) {
            const list = _.cloneDeep(this.selectedList)
            const uuid = this.createUUID()
            let newListInfo = {
                options: {
                    company: '',
                    productName: '',
                    expiration: '',
                    dates: '',
                    price: '',
                    age: ''
                },
                uuid,
                defaultData: data
            }
            list.splice(index + 1, 0, newListInfo)
            if (list.length >= 3) this.isExpand = true
            this.selectedList = list
        },
        addList: function (index) {
            const list = _.cloneDeep(this.selectedList)
            const uuid = this.createUUID()
            let newListInfo = {
                options: {
                    company: '',
                    productName: '',
                    expiration: '',
                    dates: '',
                    price: '',
                    age: ''
                },
                uuid
            }
            list.splice(index + 1, 0, newListInfo)
            if (list.length >= 3) this.isExpand = true
            this.selectedList = list
        },
        removeList: function (uuid) {
            if (this.selectedList.length <= 1) return
            const list = _.cloneDeep(this.selectedList)
            this.selectedList = list.filter((data) => data.uuid !== uuid)
        },
        expandList() {
            this.isExpand = !this.isExpand
            this.$nextTick(() => {
                window.scrollTo({
                    top: this.isExpand ? this.$refs.filter.offsetHeight : 0,
                    behavior: 'smooth'
                })
            })
        }
    },
    computed: {
        transformCompanyList: function () {
            return this.dropdownList.map((option) => {
                return {
                    label: option.value,
                    value: option.key
                }
            })
        },
        calculateTotal: function () {
            let total = 0
            this.selectedList.forEach((item) => {
                if (item.options.price) {
                    let price = parseFloat(item.options.price.replace(/,/g, ''))
                    total = total + price
                }
            })
            return this.$numberWithComma(total)
        }
    },
    data() {
        return {
            dropdownList: [],
            selectedList: [
                {
                    options: {
                        company: '',
                        productName: '',
                        expiration: '',
                        dates: '',
                        price: '',
                        age: ''
                    },
                    uuid: this.createUUID() //識別商品列
                }
            ],
            isExpand: false
        }
    },
    async mounted() {
        const routeName = this.$route.name
        if (routeName === 'ProductReceive') await this.getCompanyList()
    }
}
</script>
<style lang="scss" scoped>
.filter-container {
    margin-bottom: 20px;
}

:deep(.filter) {
    .textbox-container,
    .input-container {
        &:after {
            display: none;
        }
    }
}
.total-text {
    margin: 0;
    text-align: end;
    font-family: 'Noto Sans TC';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: $sixth-black;
    width: 97%;
}

.size {
    width: 20px;
    height: 20px;
    margin: 0 auto;
    cursor: pointer;
}
.expand {
    transform: rotate(270deg);
}
.collapse {
    transform: rotate(90deg);
}
</style>
